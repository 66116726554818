import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _c(
          "div",
          [
            _c("SelectBriefingLanguageComponent", {
              on: {
                handleChangeLanguage: function (e) {
                  return (_vm.selectedLanguage = e)
                },
              },
            }),
          ],
          1
        ),
      ]),
      !_vm.loading
        ? _c(
            "div",
            [
              _c(
                VBtn,
                {
                  attrs: {
                    color: "primary",
                    rounded: "",
                    fab: "",
                    absolute: "",
                    top: "",
                    right: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.add_slide_dialog()
                    },
                  },
                },
                [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
                1
              ),
              !_vm.slides || _vm.slides.length == 0
                ? _c(
                    VRow,
                    { staticClass: "pa-4 mt-6", attrs: { justify: "center" } },
                    [_c("p", [_vm._v(_vm._s(_vm.$t("no_slides")))])]
                  )
                : _c(VSimpleTable, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$tc("slide", 1))),
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("remove"))),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.slides, function (slide_presentation, index) {
                        return _c("tr", { key: index }, [
                          _c(
                            "td",
                            [
                              _c(VImg, {
                                staticClass: "mx-auto my-4",
                                attrs: {
                                  src: _vm.gs.get_photo_path(
                                    slide_presentation
                                  ),
                                  "max-height": "20rem",
                                  "max-width": "20rem",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "text-center" },
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "px-2 ml-1",
                                  attrs: {
                                    color: "red",
                                    "min-width": "0",
                                    small: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.open_delete_dialog(
                                        slide_presentation
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(VIcon, { attrs: { small: "" } }, [
                                    _vm._v("mdi-trash-can"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
              _vm.dialog
                ? _c(
                    VDialog,
                    {
                      model: {
                        value: _vm.dialog,
                        callback: function ($$v) {
                          _vm.dialog = $$v
                        },
                        expression: "dialog",
                      },
                    },
                    [
                      _c(
                        VCard,
                        [
                          _c(VCardTitle, [
                            _c("h4", { staticClass: "dxa_modal_title h4" }, [
                              _vm._v(_vm._s(_vm.$t("add_slide"))),
                            ]),
                          ]),
                          _c(
                            VCardText,
                            [
                              _c(
                                VForm,
                                {
                                  ref: "form",
                                  staticClass: "mt-5",
                                  on: {
                                    submit: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.add_slide()
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "text-center" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "slideImage mb-4 mx-auto",
                                        attrs: { color: "secondary" },
                                        on: { click: _vm.openFilePicker },
                                      },
                                      [
                                        _vm.slide.Slide != null
                                          ? _c(VImg, {
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                              attrs: { src: _vm.slide.Slide },
                                            })
                                          : _c(
                                              VAvatar,
                                              {
                                                attrs: {
                                                  color: "secondary",
                                                  tile: "",
                                                  height: "15rem",
                                                  width: "100%",
                                                },
                                              },
                                              [
                                                _c(
                                                  VIcon,
                                                  {
                                                    attrs: {
                                                      dark: "",
                                                      "x-large": "",
                                                    },
                                                  },
                                                  [_vm._v("mdi-pencil")]
                                                ),
                                              ],
                                              1
                                            ),
                                        _c("input", {
                                          ref: "imageSlideInput",
                                          staticStyle: { display: "none" },
                                          attrs: {
                                            id: "imageSlideInput",
                                            name: "imageSlideInput",
                                            type: "file",
                                            accept:
                                              "image/png, image/jpeg, image/bmp",
                                          },
                                          on: { change: _vm.setImageSlide },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c(
                                    VCardActions,
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "dxa_modal_btnSuccess",
                                          attrs: {
                                            color: "primary",
                                            "min-width": "100",
                                            loading: _vm.dialog_loading,
                                            type: "submit",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("save")))]
                                      ),
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "dxa_modal_btnError",
                                          attrs: {
                                            color: "red",
                                            "min-width": "100",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.dialog = false
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("cancel")))]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.error
                                ? _c(
                                    VAlert,
                                    {
                                      staticClass: "ma-2",
                                      attrs: { type: "error" },
                                    },
                                    [_vm._v(_vm._s(_vm.$t(_vm.error)))]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.delete_dialog
                ? _c(
                    VDialog,
                    {
                      model: {
                        value: _vm.delete_dialog,
                        callback: function ($$v) {
                          _vm.delete_dialog = $$v
                        },
                        expression: "delete_dialog",
                      },
                    },
                    [
                      _c("DeleteConfirmationModal", {
                        attrs: {
                          obj: _vm.delete_obj,
                          name: _vm.$t("this_slide"),
                        },
                        on: {
                          close: function ($event) {
                            _vm.delete_dialog = false
                          },
                          delete: _vm.remove_slide,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            VContainer,
            [
              _c(VProgressCircular, {
                staticStyle: { "margin-left": "50%" },
                attrs: { indeterminate: "", size: "70", color: "primary" },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }